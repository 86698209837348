.full_width_image{
    background-size: cover;
    height: 550px;
    float: left;
    width: 100%;
    padding: 75px 70px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;
    text-decoration: none;
    // margin-bottom: 30px;
    .full_width_image_title{
        color: #fff;
        font-size: 48px;
        font-weight: 800;
        position: relative;
        margin-bottom: 25px;
     //    padding-bottom:25px; 
        line-height: 1em;
        text-transform: uppercase;
    }
    .full_width_image_subtitle{
          font-size: 18px;
          font-weight: 400;
          color: #fff;
          text-transform: uppercase;
    }
}
