.form_section{
     margin-bottom: 30px;
     float: left;
     width: 100%;
    .form_section_title{
         h2{
              font-size: 30px; 
              font-weight: 800;
              margin: 0;
              text-transform: uppercase;
         }
         margin-bottom: 30px;
    }
    form{
         input[type="text"]{
               border: none;
               border-bottom: 1px solid #dfdfdf;
               height: 45px;
               width: 100%;
               font-size: 14px;
               padding: 0 15px;
               margin-bottom: 30px;
               color: #575757;
               &:focus{
                    color: #000;
                    border-bottom-color: #575757;
               }
          }
          textarea{
               width: 100%;
               border: 1px solid #dfdfdf;
               padding: 25px 30px;
               margin-bottom: 30px;
               resize: none;
               margin-top: 20px;
               height: 200px;

          }
          input[type="submit"]{
               display: block;
                    width: 170px;
                    padding: 15px 0;
                    text-transform: uppercase;
                    font-size: 13px;
                    float: right;
                    border: 2px solid #000;
                    color: #000;
                    text-decoration: none;
                    font-weight: 600;
                    transition-duration: .2s;
                        background-color: #000;
                        color: #fff;
                        &:hover{
                             opacity: .8;
                        }
          }
    }
} 