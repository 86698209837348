.contact_top{
     float: left;
     padding-top: 70px;
     padding-bottom: 110px;
      border-bottom: 1px solid #ececec;
     width: 100%;
      margin-bottom: 30px;
    .row{
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         img{
              max-width: 100%;
         }

         .contact_top_item{
              text-align: center;
              max-width: 475px;
              margin: 0 auto;
              padding: 30px 20px;
              p{
                   margin: 0;
                   font-size: 14px;
                   font-weight: 400;
                   line-height: 23px;
                   color: #575757;
                   strong{
                        font-size: 17px;
                        color: #000;
                        font-weight: 800;
                   }
              }
              ul{
                   list-style: none;
                   padding: 0; margin: 0;
                   li{
                        font-size: 14px;
                        color: #000;
                        font-weight: 800;
                        margin: 3px 0;
                         a{
                              color: #575757;
                              font-weight: 400;
                         }
                   }
              }
              &.contact_top_item--border{
                   border: 3px solid #e5e5e5;
                    padding: 40px 20px;

              }
         }
    }
}
@media (max-width: 767px){
     .contact_top .column{
          width: 100%;
          text-align: center;
     }
}