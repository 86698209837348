.intro_subpage_section{
    background-size: cover;
    height: 380px;
    float: left;
    width: 100%;
    padding: 75px 0px;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    margin-bottom: 25px;
    background-position: center;

    // margin-bottom: 30px;
    .intro_title{
        color: #fff;
        font-size: 36px;
        font-weight: 200;
        position: relative;
        padding-bottom:25px; 
        line-height: 1em;
        &::after{
                content: '';
                position: absolute;
                bottom: 0px;
                left: 0px;
                background-color: #fff;
                width: 100px;
                height: 3px;
                transition-duration: .2s;
        }
    }
}

@media (max-width: 767px){
    .intro_subpage_section {
        height: 250px;
    }
    .intro_subpage_section .container{
        float: left;
        margin: 0;
    }
}