.intro_section{
     .intro_section_content{
          .intro_section_content_intro{
               background-size: cover;
               height: 380px;
               float: left;
               width: 100%;
               padding: 75px 70px;
               display: flex;
               align-items: flex-end;
               text-decoration: none;
                background-position: center;
               margin-bottom: 30px;
               .intro_title{
                    color: #fff;
                    font-size: 36px;
                    font-weight: 200;
                    position: relative;
                    padding-bottom:25px; 
                    line-height: 1em;
                    &::after{
                         content: '';
                         position: absolute;
                         bottom: 0px;
                         left: 0px;
                         background-color: #fff;
                         width: 100px;
                         height: 3px;
                         transition-duration: .2s;
                    }
               }

               &:hover{
                    .intro_title::after{
                         width: 140px;
                    }
               }
          }

          .intro_section_content_box{
               background-size: cover;
               height: 300px;
               float: left;
               width: 100%;
               padding: 60px 0px;
               display: flex;
               align-items: flex-end;
               justify-content: flex-end;
               text-decoration: none;
               background-position: center;
               position: relative;
               margin-bottom: 30px;
               transition-duration: .5s;
               text-decoration: none;
               overflow: hidden;

               // overflow: hidden;
                .box_image{
                    position: absolute;
                    left: 0; right: 0;
                    bottom: 0; top: 0;
                    background-size: cover;
                    transition-duration: .4s;
                }
               .box_wrap{
                    width: 200px;
                    position: relative;
                    
                    .box_title{
                         color: #fff;
                         font-size: 24px;
                         font-weight: 800;
                         position: relative;
                         padding-bottom:0px; 
                         line-height: 1em;
                         position: relative;
                         z-index: 1;
                         text-transform: uppercase;
                         width: 100%;
                         margin-bottom: 15px;
                    }
                    .box_link{
                         position: relative;
                         z-index: 1;
                         width: 100%;
                         text-transform: uppercase;
                         font-size: 14px;
                         font-weight: 400;
                              color: #fff;
                              text-decoration: none;
                              transition-duration: .2s;
                              img{
                                   width: 5px;
                                   position: relative;
                                   top: -1px;
                                   margin-left: 4px;
                              }
                              &:hover{
                                   opacity: .8;
                              }
                    }
               }
               &::after{
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    // background-color: #000000;
                    width: 310px;
                    height: 100%;
                    transition-duration: .5s;
                    background-repeat: no-repeat;

                    background-image: url(../img/triangle.png);
               }
               &:hover{
                   .box_image{
                     transform: scale(1.02);
                   }
         
               }
          }
     }
}

@media (max-width: 767px){
     .intro_section .intro_section_content .intro_section_content_box{
          height: 200px;
     }
     .intro_section .intro_section_content .intro_section_content_box::after{
          background-repeat: no-repeat;
          width: 100%;
          background-position: right;
     }
     .intro_section .intro_section_content .intro_section_content_intro{
        padding: 40px;
     }
}
