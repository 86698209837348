.breadcumb_section{
    float: left;
    width: 100%;
    nav{
        .breadcrumb{
            padding: 30px 8px;
            padding-top: 5px;
            background-color: transparent;
            border-bottom: 1px solid #ececec;
            .breadcrumb-item{
                font-size: 12px;
                a{
                    color: #000;
                }
                &.active{
                    color: #000;
                    
                }
            }
        }
    }
}
.breadcrumb > li + li::before{
    content: "> "
}