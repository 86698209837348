.product{
    .product_wrap{
        .product_wrap_slider{
            float: left;
            width: 100%;
        }
        .slider-for{
            .item{
                img{
                    width: 100%;
                }
            }
        }
        .slider-nav{
            margin-top: 5px;
            .slick-track{
                padding: 0;
                margin: 0;
                margin-left: -5px;
            }
            .item{
                padding: 0 5px;
                cursor: pointer;
                img{
                    width: 100%;
                }
            }
        }
        .product_info{
            padding: 30px;
            padding-right: 0;
            float: left;
            width: 100%;
            .product_info_title{
                font-size: 24px;
                font-weight: 800;
                text-transform: uppercase;
                color: #000;
                padding-bottom: 30px;
                margin-bottom: 30px;
                border-bottom: 1px solid #e8e8e8;

            }
            .product_info_price{
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                .price{
                    font-size: 18px;
                    padding: 10px 15px;
                    border: 2px solid #e8e8e8;
                }
                .count{
                    font-size: 13px;
                    margin-left: 15px;
                    strong{

                    }
                }
            }

            .product_info_more{
                margin-bottom: 20px;
                p{
                    font-size: 14px;
                    color: #575757;
                    font-weight: 400;
                    margin: 0;
                    margin-bottom: 5px;
                    strong{
                        font-weight: 600;
                        color: #000;
                    }
                }
            }

            .product_info_description{
                font-size: 14px;
                    color: #575757;
                margin-bottom: 20px;

                    font-weight: 400;
            }

            .product_info_link{
                float: left;
                width: 100%;
                margin-bottom: 15px;

                a{
                    display: block;
                    width: calc(50% - 5px);
                    padding: 15px 0;
                    text-transform: uppercase;
                    font-size: 13px;
                    float: left;
                    border: 2px solid #000;
                    color: #000;
                    text-decoration: none;
                    text-align: center;
                    font-weight: 600;
                    transition-duration: .2s;
                        background-color: #000;
                        color: #fff;
                        &:hover{
                            opacity: .8;
                        }
                }
            }

            .product_info_locations{
                font-weight: 800;
            }
        }
        .product_description{
            margin-top: 50px;
            float: left;
            width: 100%;
            .product_description_title{
                width: 100%;
                float: left;
                font-size: 18px;
                font-weight: 800;
                color: #000;
                padding-bottom: 10px;
                border-bottom: 1px solid #ececec;
                margin-bottom: 10px;
            }
            .product_description_content{
                font-weight: 400;
                color: #575757;
                margin-bottom: 20px;
                float: left;
                width: 100%;
            }
        }
    }
}



@media (max-width: 991px){
    .product .product_wrap .product_wrap_slider{
        margin-bottom: 50px;
    }
    .product .product_wrap .product_info{
        padding: 0;
    }
}