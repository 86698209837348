.footer_section{
	float: left;
	width: 100%;
	.content{
		padding: 30px 0;
		border-top: 1px solid #ececec;
		float: left;
		width: 100%;
		.left{
			float: left;
		}
		.right{
			float: right;
			ul{
				list-style: none;
					display: inline-block;

				li{
					display: inline-block;
					float: left;
					margin-left: 10px;
					transition-duration: .1s;
					img{
						margin-bottom: 0;
						width: 25px;
						margin: 0;
					}
					&:hover{
						opacity: .8;
					}
				}
			}
			a{
				font-weight: 700;
				color: #000;
				font-style: italic;
			}
		}
	}
}

@media (max-width: 767px){
	.footer_section .content .left,
	.footer_section .content .right{
		float: left;
		width: 100%;
		text-align: center;
		ul{
			margin: 0;

			margin-top: 20px;
			padding: 0;

			li{
				margin: 0 10px;
			}
		}
	}

}
