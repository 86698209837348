.bootstrap-menu{

	float: left;
	width: 100%;


	.navbar{
		float: left;
		width: 100%;
		border: none;
		margin-bottom: 0;
		background-color: transparent;
		margin-bottom: 25px;

		.container{
			.navbar-wrap{
				padding-top: 40px;
				padding-bottom: 40px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #ececec;
			}

			.navbar-header{
				.logo{
					a{
						float: left;
						display: inline-block;
						img{
							// width: 160px;
							max-width: 100%;
						}
					}
				}

				.navbar-toggle{
					.icon-bar{

					}
				}

			}


			.navbar-collapse{
					font-size: 14px;
					margin-left: auto!important;
					padding: 0px;
					height: auto!important;
					margin: 0;

				.navTop{
					float: right;
					li{
						font-size: 12px;
						font-weight: 800;
						padding: 0;
						text-transform: uppercase;
						margin-left: 20px;
						
						a{
							padding:  0px 0px;
							display: inline-block;
							line-height: 1em;
							font-weight: 400;

							color: #000;
							transition-duration: .1s;
							img{
								margin-top: -5px;
								margin-right: 5px;
							}
							&:hover{
								opacity: .8;
							}
						}
						&.language{
							padding-left: 15px;
							margin-left: 15px;
							border-left: 1px solid rgba(0,0,0,.4);
							.language_item{
								&.language_item--active{
									span{
										text-decoration: underline;
									}
								}
								&::after{
									content: '/';
								}
								&:last-child{
									&::after{
										display: none;
									}
								}
							}
						}
					}



				}

				.navBottom{
					width: 100%;
					display: block;
					display: flex;
					justify-content: flex-end;
					margin-top: 20px;

					li{
						text-transform: uppercase;
						float: left;
						margin: 0 20px;
						&:last-child{
							margin-right: 0;
						}
						a{
							float: left;
							padding: 10px 10px;
							font-weight: 600;
							color: #000;
							transition-duration: .1s;
							font-size: 15px;

							&:hover{
								opacity: .8;
							}

						}
						&.activeNav{
							a{
								color: #000;
							}
						}

					
						&.dropdown{
							.dropdown-menu{
								left: auto;
								right: 0;
								padding: 10px;
								max-width: 360px;
								min-width: 200px;
								a{
									float: left;
									width: 100%;
									display: block;
								}
							}
						}
						&:last-child{
							a{
								padding-right: 0;
							}
							&::after{
								display: none;
							}
						}
						.dropdown-toggle{
							&::after{
								display: inline-block;
								width: 0;
								height: 0;
								margin-left: .255em;
								vertical-align: .255em;
								content: "";
								border-top: .3em solid;
								border-right: .3em solid transparent;
								border-bottom: 0;
								border-left: .3em solid transparent;
							}
						}
						&.nav-item--cart{
							margin-left: 0;
							a{
								width: 40px; height: 40px;
								border: 1px solid #e2e2e2;
								padding: 0;
								display: flex;
								align-items: center;
								justify-content: center;
								img{
									width: 20px;
								}
								&::after{
									display: none;
								}
							}
							.dropdown-menu{
								width: 360px;
								padding: 0;
								.dropdown-item{
									border: none;
									padding: 20px;
									width: 100%;
									display: flex;
									flex-wrap: wrap;
									float: left;
									width: 100%;
									height: auto;
									flex-wrap: wrap;
									border-bottom: 1px solid #e2e2e2;
									align-items: center;
									text-decoration: none;
									a{
										float: left;
										width: auto;
										border: none;
										padding: 0;
										height: auto;
									}
									.name{
										width: calc(100% - 130px);
										text-decoration: none;
									}
									.price{
										margin-left: auto;
									}
									.delete{
										a{
											display: block;
											background-color: #000;
											color: #fff;
											padding: 5px 7px;
											text-transform: none;
											margin-left: 15px;
											line-height: 1em;
											font-size: 12px;
											text-decoration: none;
											transition-duration: .2s;

											&:hover{
												opacity: .6;
											}
										}
									}
									&:hover{
										text-decoration: none;
										background-color: #e2e2e2;
									}
								
									
									&.dropdown-item--end{
										display: block;
										
										background-color: #000;
										text-align: center;
										color: #fff;
										border-bottom: none;
										text-align: center;
									}
								}
								.price-count{
											padding: 10px 0;
											font-size: 14px;
											float: left;
											text-align: center;
											width: 100%;
									}
							}
						}
					}
				}


			}
		}
	}
}

@media (max-width: 1550px){
	.bootstrap-menu .navbar .container .navbar-collapse.collapse .navBottom li::after{
		margin: 0 5px;
		margin-top: 8px;
	}
}


@media (max-width: 1180px){
	// .bootstrap-menu .navbar .container .navbar-header .logo a img{
	// }
	// .bootstrap-menu .navbar .container .navbar-header .logo .more{
	// 	img{
	// 	}
	// }
	// .bootstrap-menu .navbar .container .navbar-header .logo a::after{
	// 	height: 60px;
	// }
	.bootstrap-menu .navbar .container .navbar-header .logo{
		width: 300px;
	}
}
@media (max-width: 1024px){
	.bootstrap-menu .navbar .container .navbar-collapse.collapse .navBottom li a{
		font-size: 14px;
	}
	// .bootstrap-menu .navbar .container .navbar-collapse.collapse .navBottom li::after{
	// 	margin: 0;
	// 	margin-top: 6px;
	// }

	// .bootstrap-menu .navbar .container .navbar-collapse.collapse .navBottom{
	// 	margin-top: 0;
	// }
}

@media (max-width: 991px) {
.bootstrap-menu .navbar .container .navbar-collapse .navBottom li.nav-item--cart .dropdown-menu{
	width: 100%;
	max-width: 100%;
}
	.bootstrap-menu .navbar .container .navbar-wrap{
		flex-wrap: wrap;
	}
.bootstrap-menu .navbar .container .navbar-header .logo a img{
	width: 200px;
}
.bootstrap-menu .navbar .container .navbar-collapse .navBottom .dropdown{
	.dropdown-menu{
		position: relative;
		width: 100%;
		box-shadow: none;
	}
}
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }

	.bootstrap-menu .navbar .container .navbar-header{
		width: 100%;
	}
	.navbar-toggle{
		margin: 0;
	}
	.bootstrap-menu .navbar .container .navbar-header{
		margin-left: 0;
	}

	.bootstrap-menu .navbar .container{
		flex-wrap: wrap;
	}
	.bootstrap-menu .navbar .container .navbar-collapse.collapse,
	.bootstrap-menu .navbar .container .navbar-collapse.in,
	.bootstrap-menu .navbar .container .navbar-collapse.collapsing,
	.bootstrap-menu .navbar .container .navbar-collapse{
		margin: 0px;
		width: 100%;
		height: auto!important;
		font-size: 14px!important;
		transition-duration: 0;
		&::before{
			display: none;
		}
	}
	.bootstrap-menu .navbar .container .navbar-collapse .navTop,
	.bootstrap-menu .navbar .container .navbar-collapse.collapse .navTop{
		display: none;
	}
	.bootstrap-menu .navbar .container .navbar-collapse .navBottom li::after{
		display: none;
	}
	.bootstrap-menu .navbar .container .navbar-collapse .navBottom{
		display: block;
	}
	.bootstrap-menu .navbar .container .navbar-collapse .navBottom li{
		float: left;
		width: 100%;
		display: block;
		border-bottom: 1px solid rgba(0,0,0,.1);
		a{
			display: block;
			width: 100%;
		}
	}
	.navbar-nav{
		margin: 0;
	}
	.bootstrap-menu .navbar .container .navbar-collapse.collapse, .bootstrap-menu .navbar .container .navbar-collapse.in, .bootstrap-menu .navbar .container .navbar-collapse.collapsing, .bootstrap-menu .navbar .container .navbar-collapse{
		margin-top: 20px;
	}
	.bootstrap-menu .navbar .container .navbar-collapse .navBottom{
		margin-top: 0px!important;
	}
	.navbar-toggle{
		border-radius: 10px;
		border: 3px solid #000!important;
	}
	.navbar-default .navbar-toggle .icon-bar{
		background-color: #000;
	}
	.bootstrap-menu .navbar .container .navbar-collapse .navBottom li{
		margin: 0;
	}

	.bootstrap-menu .navbar .container .navbar-collapse .navBottom li.nav-item--cart a{
		width: 100%;
		border-top: none;
		border-bottom: none;
	}
	.bootstrap-menu .navbar .container .navbar-collapse .navBottom li.nav-item--cart{
		border: 2px solid #000;
		margin-top: 20px;
	}
	.bootstrap-menu .navbar .container .navbar-collapse .navBottom li.nav-item--cart .dropdown-menu .dropdown-item{
background-color: #f2f2f2;

	}

}