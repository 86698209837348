//// IMPORT
@import '../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../node_modules/slick-carousel/slick/slick.scss';


//SETTINGS
body, html{
	font-family: 'Montserrat', sans-serif;
	font-size: rem-calc(15px);
	max-width: 100%;
	overflow-x: hidden;
	color: #2b2b2b;
	min-width: 320px;
}

//COLORS

@import 'modules/commons/bootstrap-menu/style';

@import 'modules/sections/main_page/intro/style';
@import 'modules/sections/products/intro_subpage/style';
@import 'modules/sections/products/breadcrumb/style';
@import 'modules/sections/products/products/style';
@import 'modules/sections/contact/contact_top/style';
@import 'modules/sections/contact/form/style';
@import 'modules/sections/about_us/content/style';
@import 'modules/sections/about_us/full_width_image/style';
@import 'modules/sections/product/product_box/style';
@import 'modules/sections/product/products/style';
@import 'modules/sections/product/breadcrumb/style';


@import 'modules/commons/footer/style';
// @@include('sections//content.html')

