.products_section{
    .products_section_title{
        width: 100%;
        float: left;
        margin-bottom: 30px;
        h2{
            font-size: 30px;
            font-weight: 800;
            text-transform: uppercase;
            margin: 0;
        }
        
    }
    .products_section_content{
        text-transform: uppercase;
        
        float: left;
        width: 100%;
        .products_section_content_product{
            text-align: center;
             border-bottom: 1px solid #ececec;
            padding-bottom: 25px;
            margin-bottom: 25px;
            float: left;
            width: 100%;
            transition-duration: .4s;
            &:hover{
                .product_image{
                    img{
                        transform: scale(1.02);
                    }
                }
            }
            .product_image{
                width: 100%;
                height: 240px;
                overflow: hidden;
                img{
                    max-width: 100%;
                    width: 100%;
                    transition-duration: .4s;
                }
            }
            .product_title{
                margin-top: 30px;
                margin-bottom: 15px;
                h3{
                    font-size: 18px;
                    font-weight: 400;
                    color: #000;
                    margin: 0;
                }
                   
            }
            .product_subtitle{
                font-size: 12px;
                color: #a8a8a8;
                margin-bottom: 25px;
            }
            .product_buttons{
                .button{
                    display: block;
                    width: calc(50% - 5px);
                    padding: 15px 0;
                    text-transform: uppercase;
                    font-size: 13px;
                    float: left;
                    border: 2px solid #000;
                    color: #000;
                    text-decoration: none;
                    font-weight: 600;
                    transition-duration: .2s;
                    &:first-child{
                        margin-right: 10px;
                    }
                    &.button--black{
                        background-color: #000;
                        color: #fff;
                    }
                    &:hover{
                        opacity: .8;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px){
    .column{
            &:nth-last-child(3), &:nth-last-child(2), &:nth-last-child(1) {
                .products_section_content_product{
                    border-bottom: none;
                    padding-bottom: 0px;
                }
            } 
        }
}
@media (max-width: 768px){
    .column{
            &:nth-last-child(1) {
                .products_section_content_product{
                    border-bottom: none;
                    padding-bottom: 0px;
                }
            } 
        }
}