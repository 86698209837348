.content{
      float: left; width: 100%;
      padding-top: 30px;
      padding-bottom: 60px;
      img{
            float: left;
            margin-right: 50px; 
            margin-bottom: 40px;
      }
      h2{
            margin: 0;
            font-size: 30px;
            font-weight: 800;
            margin-bottom: 40px;
            text-transform: uppercase;
      }
      p{
            font-weight: 400;
            margin-bottom: 30px;
            line-height: 1.7em;
            color: #575757;
            strong{
                  font-size: 17px;
                  font-weight: 400;
            }
      }
}

@media (max-width: 767px){
      .content{
            img{
                  width: 50%;
                  text-align: center;
                  margin: 0 auto;
                  display: block;
                  float: none;
                  margin-bottom: 30px;
            }
      }
}